import React from 'react';
import { PropsWithChildren } from 'react';
import { Trans } from 'react-i18next';
import { default as cn } from 'classnames';
import { Box, Divider, Paper, Typography } from '@mui/material';
import ImageCarousel from '../ImageCarousel';
import { AppSettings } from '../AppSettings';
import AutoVisible from '../AutoVisible';
import './AppCard.css';

export interface AppCardProps extends React.HTMLAttributes<HTMLDivElement> {
    settings: AppSettings;
    component?: string;
    noAnimate?: boolean;
    color?: string;
    cardColor?: string;
    transparent?: boolean;
    backgroundColor?: string;
    background?: string[];
    backgroundTimer?: number;
    fill?: boolean;
    header?: string;
    subheader?: string;
    bodyContent?: string;
}

export default function AppCard(props: PropsWithChildren<AppCardProps>) {
    const { settings, component, noAnimate, color, cardColor, transparent, backgroundColor, background, backgroundTimer, fill, header, subheader, bodyContent, ...rest } = props;

    const cardBgColor = props.cardColor ? (props.fill ? props.cardColor : (props.cardColor + 'da')) : 'transparent';

    const cardTextColor = {color: props.color ? (props.color !== 'system' ? props.color : undefined) : "light"};

    return (
        <AutoVisible component={Box} props={{...rest, ...(props.component && {component: props.component}), id: props.id, className: cn(props.className, "AppCard", {NoAnimation: props.noAnimate}), sx: {backgroundColor: props.backgroundColor}}}>
            {(props.background && props.background.length > 0) && (
                <div className="Background">
                    <ImageCarousel images={props.background} widths={[600]} timer={props.backgroundTimer} />
                </div>
            )}
            <Box data-transition="visibility" className={"Card Animation"}>
                <Paper className={cn("Paper", {Fill: props.fill})} elevation={props.transparent ? 0 : 1} sx={{ backgroundColor: cardBgColor }}>
                    {props.header && 
                        <Typography {...cardTextColor} gutterBottom variant="h4" sx={{ fontWeight: '200', pb: (props.subheader ? 0 : 2) }}>
                            <Trans i18nKey={props.header} />
                            <Divider sx={{width: '128px', my: '16px', borderColor: 'var(--mui-palette-background-divider)', borderBottomWidth: '8px'}} />
                        </Typography>
                    }
                    {props.subheader && 
                        <Typography {...cardTextColor} gutterBottom variant="h5" sx={{ fontWeight: '200', pt: 1, pb: 2 }}>
                            <Trans i18nKey={props.subheader} />
                        </Typography>
                    }
                    {props.bodyContent && 
                        <Typography {...cardTextColor} gutterBottom component='span' variant="body1" sx={{ fontWeight: '200' }}>
                            <Trans i18nKey={props.bodyContent} components={{ subheader: <Typography variant="h5" sx={{ fontWeight: '200', pb: 2 }} /> }} />
                        </Typography>
                    }
                    {props.children && 
                        props.children
                    }
                </Paper>
            </Box>
        </AutoVisible>
    );
}