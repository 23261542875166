import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { List, ListItem, ListItemText, ListItemAvatar } from '@mui/material';
import Typography from '@mui/material/Typography';
import AppCard from '../AppCard';
import { AppCardProps } from '../AppCard';
import './AppMainTeam.css';

export default function AppMainTeam(props: AppCardProps) {
    const { t } = useTranslation();

    const teamMembers = ["mathias", "champ"];

    return <AppCard {...props} color="system">
        <List sx={{ width: '100%' }}>
            {teamMembers.map((memberName, index) => {
                const displayName = t('team.members.' + memberName + '.name');
                const even = (index % 2) === 0;

                return <React.Fragment key={index}>
                    <ListItem className={cn("Member", {Even: even})} alignItems="flex-start" disableGutters>
                        <ListItemAvatar>
                            <Avatar className="Avatar" alt={displayName} src={"/images/portrait/" + memberName + ".webp"} />
                        </ListItemAvatar>
                        <ListItemText
                            primary=<React.Fragment>
                                <Typography variant='h4' fontWeight='500'>{displayName}</Typography>
                                <Typography variant='h6' fontWeight='300'>
                                    {t('team.members.' + memberName + '.title')}
                                </Typography>
                            </React.Fragment>
                            secondary=<Typography variant='body1' sx={{py: 2}}>
                                {t('team.members.' + memberName + '.about')}
                            </Typography>
                        />
                    </ListItem>
                    {index < teamMembers.length - 1 &&
                        <Divider component="li" sx={{my: '16px'}} />
                    }
                </React.Fragment>;
            })}
        </List>
    </AppCard>;
}